import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Button from 'components/Button'
import OutlineButton from 'components/OutlineButton'
import ProduktCard from 'components/ProduktCard'
import feldenkraisArray from 'data/feldenkrais.json'
import { Link } from 'react-router-dom'

function Feldenkrais() {
  const feldenkrais = feldenkraisArray[0]
  const image = require('../assets/images/' + feldenkrais.imgSrc)
  return (
    <div className="pt-20 pb-10 w-full min-h-screen px-10">
      <div className="w-full max-w-6xl mx-auto md:w-11/12 lg:w-full mt-10 text-center">
        <h1 className="text-4xl text-feldenkrais font-semibold">
          Die Feldenkrais® Methode
        </h1>
        <p className="mx-auto mt-5 md:w-3/4 lg:w-1/2 text-lg">
          Feldenkrais ist für alle die neugierig sind und Freude daran haben
          ihre Bewegungsfähigkeiten spielerisch zu erweitern.
        </p>
      </div>
      <div className="w-full flex flex-col-reverse ipad:flex-row max-w-5xl mx-auto mt-10 md:px-6 text-center sm:text-left gap-4 sm:gap-8 ipad:gap-16">
        <div className="w-full ipad:w-1/2">
          <p className="whitespace-pre-line leading-7">{feldenkrais.text}</p>
          <ul className="list-inside list-disc ml-4 md:ml-10 mt-3">
            {feldenkrais.textList.map((li, i) => {
              return <li key={i}>{li}</li>
            })}
          </ul>
        </div>
        <div className="w-full sm:w-3/4 ipad:w-1/2 mx-auto rounded-md overflow-hidden mb-5 md:mb-0 flex items-center">
          <img
            src={image.default}
            alt={feldenkrais.type}
            className="w-full h-full object-cover ipad:max-h-auto rounded-md overflow-hidden"
          />
        </div>
      </div>
      <div className="w-full lg:w-11/12 xl:w-full max-w-5xl mx-auto mt-10 flex flex-col md:px-6 ipad:flex-row ipad:gap-16 justify-center">
        {feldenkrais.subtypes.map((feld, i) => {
          return (
            <div key={i} className="flex flex-col flex-1 w-full ">
              <div className={`py-5 flex flex-col flex-1 md:flex-row w-full`}>
                <div
                  className={`flex flex-col flex-1 text-center sm:text-left `}
                >
                  <h2 className="font-semibold text-2xl md:text-3xl text-gray-600 mb-4">
                    {feld.type}
                  </h2>
                  <p className="whitespace-pre-line text-base">
                    {feld.description}
                  </p>
                  <div className="flex gap-6 mt-8 justify-end items-center flex-col md:flex-row">
                    <Link
                      to={`/kontakt?betreff=Kurse&kurs=${feldenkrais.type}`}
                    >
                      <OutlineButton
                        text="Termin anfragen"
                        style="px-6 py-1 md:py-0.5 w-auto border-feldenkrais text-feldenkrais hover:bg-feldenkrais"
                      />
                    </Link>
                    <Link to="/kurse" className="text-feldenkrais">
                      <span>Kurse & Termine</span>
                      <FontAwesomeIcon
                        className="ml-2 cursor-pointer"
                        icon={faArrowRight}
                        size="sm"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Feldenkrais
