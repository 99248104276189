import { useState, useEffect } from 'react'

import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import logo from 'assets/images/logoTop.png'
import NavLink from './NavLink'
import OutlineButton from 'components/OutlineButton'
import Button from 'components/Button'

import LoginModal from './LoginModal'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faTimes,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons'

import massagen from 'data/massagen.json'
import bewegung from 'data/bewegung.json'
import feldenkrais from 'data/feldenkrais.json'

import api from 'config/api'

function Nav() {
  let [navOpened, setNavOpened] = useState(false)
  let [modal, setModal] = useState(false)
  let [modalState, setModalState] = useState('fadeIn')
  let [loggedIn, setLoggedIn] = useState(false)
  let [role, setRole] = useState('')

  const { pathname } = useLocation()
  const navigate = useNavigate()
  let [urlparams, setUrlparams] = useSearchParams()
  let error = urlparams.get('error')

  useEffect(() => {
    api
      .get('/scripts/checkLoggedIn.php')
      .then((res) => {
        if (res.data.loggedIn) {
          setLoggedIn(true)
          setRole(res.data.role)
        }
      })
      .catch((error) => {})
    if (error) {
      openModal()
      urlparams.delete('error')
    }
  }, [])

  if (window.innerWidth < 1024) {
    useEffect(() => {
      setTimeout(() => {
        setNavOpened(false)
      }, 300)
    }, [pathname])
  }

  useEffect(() => {
    if (!error) {
      setTimeout(() => {
        setModal(false)
        setModalState('fadeIn')
        setUrlparams({})
      }, 650)
    }
  }, [modalState])

  const toggleNavbar = (e) => {
    setNavOpened(!navOpened)
  }

  const handleButtonClick = (e) => {
    setNavOpened(false)
    setTimeout(() => {
      openModal()
    }, 900)
  }

  const openModal = () => {
    setModal(true)
  }

  const logout = () => {
    api.post('/scripts/logout.php').then(() => {
      window.location.reload()
    })
  }

  return (
    <nav className="nav fixed w-full h-20 shadow-md bg-white z-50">
      {modal ? (
        <LoginModal
          setModal={setModal}
          modalState={modalState}
          setModalState={setModalState}
        ></LoginModal>
      ) : (
        ''
      )}
      <div
        className={`flex flex-row justify-between pl-1 pr-8 md:px-10 lg:pl-0 lg:pr-4 2xl:px-20 h-full max-w-max mx-auto`}
      >
        <Link to="/" className="flex items-center h-full">
          <img src={logo} alt="Logo" className="h-4/6 xl:h-3/4 mr-1 xl:mr-2" />
          <span className="text-xl xl:text-2xl font-semibold">
            <span className="text-primary">Ma</span>
            <span className="text-bewegung">Be</span>
            <span className="text-feldenkrais">Fe</span>
          </span>
        </Link>
        <div
          className={`fixed top-0 bottom-0 py-20 lg:py-0 ${
            navOpened ? 'right-0' : 'right-full'
          } w-full flex flex-col justify-center bg-white lg:w-auto lg:relative lg:top-auto lg:bottom-auto lg:right-0 lg:flex-row items-center gap-8 xl:gap-12 2xl:gap-14 transition-all duration-850 ease-in-out`}
        >
          <NavLink to="/" name="Home" />
          {/* <NavLink to="/team" name="Das Team" /> */}
          <NavLink to="/massagen" name="Massagen" onClick={toggleNavbar}>
            {massagen.map((massage, i) => {
              return (
                <Link key={i} className="nav-dropdown-entry" to={massage.href}>
                  {massage.type}
                </Link>
              )
            })}
          </NavLink>
          <NavLink to="/bewegung" name="Bewegung"></NavLink>
          <NavLink to="/feldenkrais" name="Feldenkrais"></NavLink>
          <NavLink to="/kurse" name="Kurse" />
          <NavLink to="/preise" name="Preise" />
          {window.innerWidth < 1024 && (
            <div className="flex flex-col items-center justify-end gap-4 mt-4">
              <Link to="/kontakt" className="h-10">
                <OutlineButton
                  text="Termin anfragen"
                  style="w-52 h-full"
                  onClick={handleButtonClick}
                />
              </Link>
              {!loggedIn && (
                <Button
                  text="Login"
                  style="w-52 h-10"
                  onClick={handleButtonClick}
                  disabled={modal}
                />
              )}
              {loggedIn && role == 'admin' && (
                <Link
                  to="/dashboard"
                  className={`${
                    pathname == '/dashboard' ? 'text-primary' : 'text-black'
                  } hover:text-primary transition-colors duration-300`}
                >
                  Dashobard
                </Link>
              )}
              {loggedIn && (
                <span
                  className={`text-black hover:text-primary transition-colors duration-300`}
                  onClick={() => logout()}
                >
                  Logout
                </span>
              )}
            </div>
          )}
          {window.innerWidth < 1024 && (
            <div className="lg:hidden absolute top-7 right-9">
              <FontAwesomeIcon
                className="cursor-pointer"
                icon={faTimes}
                size="lg"
                onClick={toggleNavbar}
              />
            </div>
          )}
        </div>
        {window.innerWidth >= 1024 && (
          <div className="hidden lg:flex items-center justify-end gap-6">
            <Link to="/kontakt" className="h-2/5 xl:h-1/2">
              <OutlineButton
                text="Termin anfragen"
                style="px-3 xl:px-6 h-full "
              />
            </Link>
            {!loggedIn && (
              <Button
                text="Login"
                style="px-5 xl:px-10 h-2/5 xl:h-1/2"
                onClick={openModal}
                disabled={modal}
              />
            )}
            {loggedIn && role == 'admin' && (
              <div>
                <Link
                  to="/dashboard"
                  className={`${
                    pathname == '/dashboard' ? 'text-primary' : 'text-black'
                  } hover:text-primary transition-colors duration-300`}
                >
                  Dashobard
                </Link>
                <FontAwesomeIcon
                  className={`ml-5 text-gray-600 hover:text-primary transition-colors duration-300 cursor-pointer`}
                  icon={faSignOutAlt}
                  size="lg"
                  onClick={() => logout()}
                  title="Logout"
                />
              </div>
            )}
          </div>
        )}
        <div className={`flex lg:hidden items-center justify-end gap-6`}>
          <FontAwesomeIcon
            className="cursor-pointer"
            icon={faBars}
            size="lg"
            onClick={toggleNavbar}
          />
        </div>
      </div>
    </nav>
  )
}

export default Nav
