import OutlineButton from 'components/OutlineButton'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import massagen from 'data/massagen.json'

function Massage() {
  const location = useLocation()

  const loadProdukt = () => {
    return massagen
      .filter((p) => {
        const hrefSplit = p.href.split('/')
        const locationSplit = location.pathname.split('/')
        if (locationSplit.pop() === hrefSplit.pop()) return 1
        return 0
      })
      .pop()
  }

  const [produkt, setProdukt] = useState(loadProdukt)
  useEffect(() => {
    setProdukt(loadProdukt)
  }, [location.pathname])
  const image = require('../assets/images/' + produkt.imgSrc)
  return (
    <div className="pt-20 pb-10 sm:pb-16 px-5 w-full sm:w-2/3 lg:w-1/2 sm:px-0 max-w-3xl mx-auto">
      <div className="mx-auto mt-10 w-11/12 sm:w-full h-48 sm:h-72 ipad:h-96 rounded-md overflow-hidden">
        <img
          src={image.default}
          className="w-full h-full object-cover"
          alt=""
        />
      </div>
      <div className="mt-6">
        <h1 className="font-bold text-2xl sm:text-4xl text-center sm:text-left">
          {produkt.type}
        </h1>
        {produkt.note && (
          <p className="mt-6 font-semibold text-base sm:text-lg whitespace-pre-line text-center sm:text-left">
            {produkt.note}
          </p>
        )}
        <div className="mt-6 sm:mt-4 text-base whitespace-pre-line text-center sm:text-left leading-6">
          {produkt.text.split('\n\n').map((line, index) => {
            return (
              <p key={index} className="mt-3">
                {line}
              </p>
            )
          })}
        </div>
      </div>
      <div className="mt-8 w-full sm:w-56">
        <Link to={`/kontakt?betreff=Massage&kurs=${produkt.type}`}>
          <OutlineButton
            text="Termin anfragen"
            style="px-6 py-1 md:py-0.5 w-auto border-primary text-primary hover:bg-primary"
          />
        </Link>
      </div>
    </div>
  )
}

export default Massage
