import { Link } from 'react-router-dom'

function Impressum() {
  return (
    <div className="py-20 px-10 w-full sm:w-2/3 ipad:w-1/2 sm:px-0 max-w-3xl mx-auto">
      <div className="mt-10">
        <div>
          <h1 className="font-bold text-4xl mb-6">Impressum</h1>
          <div className="flex-1 mb-6 w-full">
            <iframe
              className="w-full"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d940.558420600603!2d16.330781560624455!3d48.181552740461406!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476da81661153985%3A0x1a9a103d7f3d2fdd!2sArndtstra%C3%9Fe%2087%2F5%2C%201120%20Wien!5e0!3m2!1sde!2sat!4v1635249651266!5m2!1sde!2sat"
              // width="650"
              height="400"
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
          <div className="flex flex-col gap-1.5 text-lg">
            <p className="text-xl font-semibold">
              Praxis für Massage Bewegung Feldenkrais
            </p>
            <a
              href="https://www.google.com/maps/place/Arndtstra%C3%9Fe+87,+1120+Wien"
              target="_blank"
              rel="noreferrer"
            >
              <p className="text-black hover:text-primary-dark transition-all duration-200">
                Arndtstraße 87/5, 1120 Wien
              </p>
            </a>
            <div>
              <p className="font-semibold">Telefonnummer</p>
              <p>01/812 44 71</p>
            </div>
            <div>
              <p className="font-semibold">E-Mail</p>
              <a href="mailto:praxis@mabefe.at">
                <p className="text-black hover:text-primary-dark transition-all duration-200">
                  praxis@mabefe.at
                </p>
              </a>
            </div>
            <div>
              <p className="font-semibold">Öffnungszeiten</p>
              <p>Montag - Freitag: 07:00 - 20:00 Uhr</p>
            </div>
          </div>
        </div>
        <div className="mt-8">
          <h1 className="font-bold text-2xl mb-2">Urheberrechtshinweis</h1>
          <p>
            Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos)
            unterliegen dem Urheberrecht. Falls notwendig, werden wir die
            unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich
            verfolgen.
          </p>
        </div>
        <div className="mt-8">
          <h1 className="font-bold text-2xl mb-2">Bildernachweis</h1>
          <p>
            Die Bilder, Fotos und Grafiken auf dieser Webseite sind zum Teil
            urheberrechtlich geschützt. <br />
            <br /> Die Rechte jener geschützten Bilder liegen bei der folgenden
            Fotografin:
          </p>
          <ul className="list-disc ml-10 mt-2">
            <li>
              <a
                href="https://www.odorizzi.net/"
                target="_blank"
                rel="noreferrer"
                className="font-semibold text-black hover:text-primary-dark transition-colors duration-200"
              >
                Pia Odorizzi
              </a>
              <br />
              <span>E-Mail: </span>
              <a
                href="mailto:pia@odorizzi.net"
                className="text-black hover:text-primary-dark transition-colors duration-200"
              >
                pia@odorizzi.net
              </a>
            </li>
          </ul>
        </div>
        <div className="mt-8">
          <h1 className="font-bold text-2xl mb-2">
            Haftung für Links auf dieser Webseite
          </h1>
          <p>
            Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt
            wir nicht verantwortlich sind. Haftung für verlinkte Websites
            besteht für uns nicht, da wir keine Kenntnis rechtswidriger
            Tätigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch
            bisher nicht aufgefallen sind und wir Links sofort entfernen würden,
            wenn uns Rechtswidrigkeiten bekannt werden.
          </p>
          <br />
          <p>
            Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte
            wir Sie uns zu kontaktieren. Entweder über unser{' '}
            <Link
              to="/kontakt"
              className="underline hover:text-primary-dark transition-colors duration-200"
            >
              Kontaktformular
            </Link>{' '}
            oder direkt per Mail. Sie finden die Kontaktdaten oben.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Impressum
