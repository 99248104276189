import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Button from 'components/Button'
import OutlineButton from 'components/OutlineButton'
import ProduktCard from 'components/ProduktCard'
import bewegung from 'data/bewegung.json'
import { Link } from 'react-router-dom'

function BewegungView() {
  return (
    <div className="pt-20 pb-10 w-full min-h-screen px-10">
      <div className="w-full max-w-6xl mx-auto md:w-11/12 lg:w-full mt-10 text-center">
        <h1 className="text-4xl text-bewegung font-semibold">Bewegung</h1>
        <p className="mx-auto mt-5 md:w-3/4 lg:w-3/5 text-lg">
          Wer regelmäßig Bewegung macht, hat den größten Gewinn für das eigene
          Wohlbefinden. Bei körperlichen Aktivitäten wie Gesundheitsgymnastik,
          Yoga oder Feldenkrais werden verschiedene Übungen kombiniert, die die
          Koordinationsfähigkeit, Kraft, Beweglichkeit und das Körperbewusstsein
          verbessern.
        </p>
      </div>
      <div className="w-full lg:w-11/12 xl:w-full max-w-5xl mx-auto mt-10 justify-center">
        {bewegung.map((bew, i) => {
          const image = require('../assets/images/' + bew.imgSrc)
          return (
            <div key={i} className="w-full mt-5 md:mt-16 ">
              <div
                className={`py-5 flex flex-col ${
                  i % 2 == 0 ? 'md:flex-row-reverse' : 'md:flex-row'
                } w-full`}
              >
                <div className="w-full md:w-2/5 ipad:w-1/3 rounded-md overflow-hidden mb-5 md:mb-0 flex items-center">
                  <img
                    src={image.default}
                    alt={bew.type}
                    className="w-full h-full object-cover max-h-60 ipad:max-h-auto rounded-md overflow-hidden"
                  />
                </div>
                <div
                  className={`flex-1 text-center sm:text-left ${
                    i % 2 == 0 ? 'md:pr-7 ipad:pr-14' : 'md:pl-7 ipad:pl-14'
                  }`}
                >
                  <h2 className="font-semibold text-2xl md:text-3xl text-gray-600 mb-4">
                    {bew.type}
                  </h2>
                  <p className="text-base">{bew.text}</p>
                  <div className="flex gap-6 mt-8 md:mt-4 justify-end items-center flex-col md:flex-row">
                    <Link to={`/kontakt?betreff=Kurse&kurs=${bew.type}`}>
                      <OutlineButton
                        text="Termin anfragen"
                        style="px-6 py-1 md:py-0.5 w-auto border-bewegung text-bewegung hover:bg-bewegung"
                      />
                    </Link>
                    <Link to="/kurse" className="text-bewegung">
                      <span>Kurse & Termine</span>
                      <FontAwesomeIcon
                        className="ml-2 cursor-pointer"
                        icon={faArrowRight}
                        size="sm"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              {bew.subtypes && (
                <div className="flex flex-col md:flex-row justify-center gap-12 w-full mt-10 flex-wrap xl:flex-nowrap">
                  {bew.subtypes.map((subBew, idx) => {
                    return (
                      <div
                        key={idx}
                        className="w-full md:w-5/12 ipad:w-1/3 text-center flex flex-col gap-5 min-h-full"
                      >
                        <h3 className="text-xl text-bewegung">{subBew.type}</h3>
                        <p>{subBew.description}</p>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default BewegungView
