import about from 'assets/images/DiePraxis.jpg'

import 'styles/About.scss'

function About() {
  return (
    <div
      id="about"
      className="w-full bg-primary flex items-center justify-center py-10 sm:py-20"
    >
      <div className="w-full mx-4 sm:mx-0 sm:w-4/5 max-w-7xl bg-white rounded-2xl flex flex-col items-center px-6 pt-14 pb-6 xl:flex-row sm:px-20 sm:py-14 gap-10">
        <div className="w-full lg:w-4/5 xl:w-3/4 text-center lg:text-left">
          <h1 className="font-semibold text-4xl sm:text-5xl text-gray-600 mb-10">
            <span className="relative underlined">Die Praxis</span>
          </h1>
          <p className="about-text">
            Massage in verschiedenster Form wird überall auf der Welt
            praktiziert und hilft dem Menschen beim Entspannen und im
            Heilprozess.
          </p>
          <p className="about-text">
            Sich zu bewegen und beweglich bleiben tut gut und stärkt sowohl
            geistig wie körperlich.
          </p>
          <p className="about-text">
            Die Feldenkrais-Methode fördert durch bewusst ausgeführte Bewegung
            Koordination und Selbstorganisation.
          </p>
          <p className="about-text">
            Sie finden hier an einem Ort die Möglichkeit für entspannende
            Massagen, kräftigende Rückengymnastik, aktivierendes Hata Yoga und
            durch die Feldenkrais-Methode inspirierendes zu erfahren.
          </p>
          <p className="about-text">
            Unsere Motivation ist die Zufriedenheit und Gesundheit unserer
            Kunden.
          </p>
          <p className="about-text">
            Mit Freude und Kompetenz möchten wir Sie begleiten.
          </p>
          <p className="about-text">
            Die langjährige Treue unserer Kunden bestätigt unseren Weg.
          </p>
          {/* <p className="about-text">
            Wir nehmen uns und unsere Umwelt über körperliche Eindrücke und
            Erfahrungen wahr - auch unsere Emotionen leiten und bewegen uns.
          </p>
          <p className="about-text">
            Wie fühlt es sich z.B. an, wenn Sie an ein schönes - und wenn Sie an
            ein schreckliches Erlebnis denken?
          </p>
          <p className="about-text">
            Sich zu bewegen und beweglich bleiben tut gut und stärkt sowohl
            geistig wie körperlich.
          </p> */}
        </div>
        <div className="w-full h-full sm:w-3/5 xl:w-1/2 flex justify-end">
          <div className="w-full h-full 2xl:w-3/4 rounded-3xl overflow-hidden">
            <img
              src={about}
              alt="Kurs Raum"
              className="relative block h-full w-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
