import { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import 'styles/Nav.scss'

function NavLink({ to, name, children }) {
  const location = useLocation()
  const pathname = location.pathname
  const active = (to != '/' && pathname.startsWith(to)) || pathname === to
  const navigate = useNavigate()

  const setFocus = (e, focus = true) => {
    if (focus) {
      e.target.children[0].classList.add('focus')
    } else if (e.target.children.length > 0)
      e.target.children[0].classList.remove('focus')
    e.stopPropagation()
  }

  const sendTo = (e) => {
    if (e.target.classList.contains('focus')) {
      e.target.classList.remove('focus')
      navigate(to)
    } else e.target.classList.add('focus')
  }

  return children &&
    window.matchMedia('(pointer: coarse)').matches &&
    window.innerWidth >= 1024 ? (
    <div
      tabIndex={1}
      className="relative lg:h-full flex flex-col items-center justify-center"
      onClick={(e) => sendTo(e)}
      onBlur={(e) => setFocus(e, false)}
    >
      <span className={`nav-link ${active ? 'active' : ''}`}>
        {name}
        {children ? (
          <FontAwesomeIcon
            className="cursor-pointer text-gray-600 ml-1"
            icon={faChevronDown}
            size="xs"
          />
        ) : (
          ''
        )}
      </span>
      {children ? <div className={`nav-dropdown`}>{children}</div> : ''}
    </div>
  ) : (
    <Link
      to={to}
      className="relative lg:h-full flex flex-col items-center justify-center"
    >
      <span className={`nav-link ${active ? 'active' : ''}`}>
        {name}
        {children ? (
          <FontAwesomeIcon
            className="cursor-pointer text-gray-600 ml-1"
            icon={faChevronDown}
            size="xs"
          />
        ) : (
          ''
        )}
      </span>
      {children ? <div className={`nav-dropdown`}>{children}</div> : ''}
    </Link>
  )
}

NavLink.propTypes = {
  to: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.node,
}

export default NavLink
