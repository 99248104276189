function Datenschutz() {
  return (
    <div className="py-20 px-10 w-full sm:w-2/3 ipad:w-1/2 sm:px-0 max-w-3xl mx-auto">
      <div className="mt-10">
        <div>
          <h1 className="font-bold text-4xl mb-6">Datenschutz</h1>
          <div className="mt-8">
            <h1 className="font-bold text-2xl mb-2">Betroffenenrechte</h1>
            <p>
              Als Betroffene stehen Ihnen folgende Rechte zu: Recht auf Auskunft
              (Artikel 15 DSGVO), Recht auf Berichtigung (Artikel 16 DSGVO“,
              Recht auf Löschung (Artikel 17 DSGVO), Recht auf Einschränkung
              (Artikel 18 DSGVO), Datenübertragbarkeit (Artikel 20 DSGVO) sowie
              Recht auf Widerspruch (Artikel 21 DSGVO). Die genannten
              Betroffenenrechte können Sie bei Ihrem Besuch in der Praxis
              geltend machen oder alternativ per E-Mail. Zur Feststellung Ihrer
              Identität bitten wir Sie aber persönlich zu erscheinen.
            </p>
          </div>
          <div className="mt-8">
            <h1 className="font-bold text-2xl mb-2">
              Beschwerderecht bei der Datenschutzbehörde
            </h1>
            <p>
              Sollte es Anlass zu Beschwerden wegen der Verarbeitung Ihrer Daten
              durch uns geben, so können Sie sich damit auch jederzeit an die
              österreichische Datenschutzbehörde als Aufsichtsbehörde wenden:
            </p>
            <ul className="ml-6 mt-2 list-inside list-disc">
              <li>
                <b>Addresse:</b>{' '}
                <a
                  href="https://www.google.at/maps/place/Barichgasse+40-42,+1030+Wien"
                  target="_blank"
                  className="hover:text-primary-dark transition-colors duration-200"
                  rel="noreferrer"
                >
                  Barichgasse 40-42, 1030 Wien
                </a>
              </li>
              <li>
                <b>Tel:</b> +43 1 52 152-0
              </li>
              <li>
                <b>E-Mail:</b>{' '}
                <a
                  href="mailto:dsb@dsb.gv.at"
                  target="_blank"
                  className="hover:text-primary-dark transition-colors duration-200"
                  rel="noreferrer"
                >
                  dsb@dsb.gv.at
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Datenschutz
