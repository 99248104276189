import AngebotCard from './AngebotCard'

import massagen from 'assets/images/massagen/massage-thumbnail.jpg'
import bewegung from 'assets/images/bewegung/Gymnastik.jpg'
import feldenkrais from 'assets/images/feldenkrais/Feldenkrais.jpg'
import { Element } from 'react-scroll'

function Angebot() {
  return (
    <Element
      name="angebot"
      id="angebot"
      className="w-full pb-16 sm:pb-32 sm:pt-6 flex flex-col items-center"
    >
      <h1 className="font-semibold text-4xl sm:text-5xl text-gray-600 mb-10">
        <span className="relative underlined underlined-sub">
          Unser Angebot
        </span>
      </h1>
      <div className="flex justify-center gap-8 lg:gap-16 w-10/12 md:w-4/5 mt-10 max-w-7xl flex-wrap xl:flex-nowrap">
        <AngebotCard
          to="/massagen"
          img={massagen}
          title="Massage"
          info="Massage nach Erkrankung oder Verletzung ist schon lange ein wichtiger Bestandteil von 
          Therapiekonzepten. Instinktiv reiben oder kneten wir an schmerzhaften Stellen. "
          style="text-primary hover:text-primary-dark"
        ></AngebotCard>
        <AngebotCard
          to="/bewegung"
          img={bewegung}
          title="Bewegung"
          info="Wer regelmäßig Bewegung macht, hat den größten Gewinn für das eigene Wohlbefinden. Bei 
          körperlichen Aktivitäten wie Gesundheitsgymnastik, Yoga oder Feldenkrais werden verschiedene 
          Übungen kombiniert, die die Koordinationsfähigkeit, Kraft, Beweglichkeit und das Körperbewusstsein
          verbessern."
          style="text-bewegung hover:text-bewegung-dark"
        ></AngebotCard>
        <AngebotCard
          to="/feldenkrais"
          img={feldenkrais}
          title="Feldenkrais"
          info="Feldenkrais ist für alle die neugierig sind und Freude daran haben ihre Bewegungsfähigkeiten spielerisch zu erweitern."
          style="text-feldenkrais hover:text-feldenkrais-dark"
        ></AngebotCard>
      </div>
    </Element>
  )
}

export default Angebot
