import usShadow from 'assets/images/PortraitOfUs.svg'
import us from 'assets/images/PortraitOfUs.jpg'
import Button from 'components/Button'
import OutlineButton from 'components/OutlineButton'

import { Link } from 'react-router-dom'
import { scroller } from 'react-scroll'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import 'styles/Landing.scss'
import ScrollIndicator from './ScrollIndicator'

function Landing() {
  const scrollToAngebot = () => {
    scroller.scrollTo('angebot', {
      duration: 1000,
      smooth: true,
      offset: -80,
    })
  }

  return (
    <div className="w-full h-screen pt-20 px-5 height-xxs:px-10 sm:px-0 lg:pl-16 lg:pr-10 max:pr-20 flex items-center justify-center lg:gap-10 xl:gap-20 2xl:gap-32 max:gap-56 relative overflow-hidden">
      <div className="lg:h-1/2 2xl:h-3/5 lg:relative z-0">
        <img
          className="h-full w-full absolute left-0 top-20 lg:relative overflow-hidden object-cover 2xl:object-contain lg:top-0"
          src={window.innerWidth < 1024 ? us : usShadow}
        ></img>
        <div className="lg:hidden bg-black opacity-80 absolute left-0 right-0 top-0 bottom-0"></div>
        <div className="hidden lg:block absolute top-0 bottom-0 left-0 right-0">
          <span className="text transform -rotate-90 top-1/2 -translate-y-1/2 lg:-left-1/4 xl:-left-24 -translate-x-5 text-bewegung">
            Bewegung
          </span>
          <span className="text transform lg:-top-6 xl:-top-16 -translate-x-1/2 left-1/2 text-primary">
            Massage
          </span>
          <span className="text transform rotate-90 top-1/2 -translate-y-1/2 lg:-right-1/4 xl:-right-24 translate-x-5 text-feldenkrais">
            Feldenkrais
          </span>
        </div>
      </div>
      <div className="lg:mb-10 xl:mb-0 text-white lg:text-black flex flex-col items-center justify-center z-10 -mt-10 sm:-mt-20 lg:mt-0">
        <div className="w-full inline sm:block">
          <h1 className="heading">Herzlich Willkommen in</h1>
          <h1 className="heading sm:ml-32 sm:mt-5">
            unserer Praxis <span className="text-primary">Ma</span>
            <span className="text-bewegung">Be</span>
            <span className="text-feldenkrais">Fe</span>!
          </h1>
        </div>
        <div className="w-full mt-5 height-xxs:mt-10  max:mt-28 sm:pl-20">
          <h2 className="text-xl sm:text-3xl 2xl:text-4xl font-semibold max-w-xl max:max-w-3xl">
            Wir sind mit unserer Erfahrung und Kompetenz für Ihre Gesundheit da.
          </h2>
        </div>
        <div className="w-full mt-5 height-xxs:mt-10 sm:mt-20 max:mt-28 flex flex-col sm:flex-row gap-4 sm:gap-8 justify-end">
          <Button
            text="Unser Angebot"
            style="px-8 py-2 font-semibold text-base sm:text-lg 2xl:text-xl flex flex-row gap-2"
            onClick={scrollToAngebot}
          >
            <FontAwesomeIcon
              className="cursor-pointer"
              icon={faArrowRight}
              size="1x"
            />
          </Button>
          <Link to={{ pathname: '/kontakt' }} className="h-2/5 xl:h-1/2">
            <OutlineButton
              text="Termin anfragen"
              style="px-8 py-2 font-semibold text-lg 2xl:text-xl "
            />
          </Link>
        </div>
      </div>
      <ScrollIndicator />
    </div>
  )
}

export default Landing
