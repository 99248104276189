import PageUp from './PageUp'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

function Footer() {
  const { pathname } = useLocation()
  const [scroll, setScroll] = useState(false)
  useEffect(() => {
    setScroll(document.body.scrollHeight > window.innerHeight)
  })

  return (
    <div className="w-full bg-primary flex flex-col items-center justify-center relative py-6">
      <div className="w-11/12 md:w-4/5 max-w-max mx-auto text-white flex flex-col items-center justify-center gap-3 sm:gap-6">
        <div className="w-full flex flex-col items-center gap-3 sm:gap-0 sm:flex-row justify-between uppercase overflow-hidden">
          <Link to="/impressum">
            <span>Impressum</span>
          </Link>
          <a href="/downloads/AGB.pdf" target="_blank">
            <span>AGB</span>
          </a>
          <Link to="/datenschutz">
            <span>Datenschutz</span>
          </Link>
          <Link to="/preise">
            <span>Behandlungskosten</span>
          </Link>
          <Link to="/kontakt">
            <span>Kontakt</span>
          </Link>
        </div>
        <div className="flex flex-col sm:flex-row gap-0 sm:gap-2 justify-center items-center text-center">
          <span>@Copyright {new Date().getFullYear()} </span>
          <span>massage-bewegung-feldenkrais.at</span>
        </div>
        {scroll && <PageUp />}
      </div>
    </div>
  )
}

export default Footer
