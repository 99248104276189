import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'

function Accordion({ title, children, titleStyle, dropwdownIcon, state }) {
  const [active, setActive] = useState(state ?? false)
  const toggleActive = () => {
    setActive(!active)
  }

  return (
    <div className={`w-full py-5`}>
      <div
        className={`transition-colors duration-300 cursor-pointer ${
          titleStyle ?? ''
        }`}
        onClick={toggleActive}
      >
        <span>{title}</span> {dropwdownIcon}
      </div>
      <div
        className={`overflow-hidden ${
          active ? 'max-h-full sm:max-h-screen ' : 'max-h-0'
        } transition-all sm:duration-1000 ease-in-out`}
      >
        <div className="h-4 w-full"></div>
        {children}
      </div>
    </div>
  )
}

Accordion.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  dropwdownIcon: PropTypes.node,
  titleStyle: PropTypes.string,
  state: PropTypes.bool,
}

export default Accordion
