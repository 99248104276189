import { useEffect, useState } from 'react'
import api from 'config/api'
import { Navigate, useNavigate } from 'react-router'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrashAlt,
  faPlus,
  faCheck,
  faUpload,
} from '@fortawesome/free-solid-svg-icons'
import { useSearchParams } from 'react-router-dom'

function Dashboard() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [showData, setShowData] = useState(false)
  const [toastTitle, setToastTitle] = useState('')
  const navigate = useNavigate()
  const [kurse, setKurse] = useState({})
  const [showToast, setShowToast] = useState(false)
  const [fadeIn, setFadeIn] = useState(false)

  const [preisliste, setPreisliste] = useState({})
  const [agb, setAgb] = useState({})
  const [fileError, setFileError] = useState(null)

  const headings = {
    from: 'Von',
    to: 'Bis',
    day: 'Wochentag',
    time: 'Uhrzeit',
    online: 'Online',
    full: 'Voll',
  }

  const empty = {
    id: 0,
    from: ' ',
    to: ' ',
    time: '00:00',
    day: 'Montag',
    online: false,
    full: false,
  }

  useEffect(() => {
    api.get('/scripts/checkPermission.php').then((res) => {
      if (res.data.error) {
        navigate('/')
        return
      } else if (res.data.success) {
        setShowData(true)
        getKurse()
      }
    })
    let success = searchParams.get('status') === 'success'
    let type = searchParams.get('type')
    if (success) {
      if (type == 'password') createToast('Passwort erfolgreich geändert!')
      else if (type == 'file') createToast('Datei erfolgreich hochgeladen!')
    } else {
      if (type == 'password')
        createToast('Passwort konnte nicht geändert werden!')
      else if (type == 'file')
        createToast('Datei konnte nicht hochgeladen werden!')
    }
  }, [])

  const formatDate = (date) => {
    const d = date.split('.')
    const out = `${d[2]}-${d[1]}-${d[0]}`
    return out
  }

  const formatDateBack = (date) => {
    const d = date.split('-')
    const out = `${d[2]}.${d[1]}.${d[0]}`
    return out
  }

  const addTermin = (type, sub) => {
    let k = Object.assign({}, kurse)
    let newT = Object.assign({}, empty)
    if (sub) {
      const lastId = k[type][sub].termine.reverse()[0]?.id ?? -1
      k[type][sub].termine.reverse()
      newT.id = lastId + 1
      k[type][sub].termine.push(newT)
    } else {
      const lastId = k[type].termine.reverse()[0]?.id ?? -1
      k[type].termine.reverse()
      newT.id = lastId + 1
      k[type].termine.push(newT)
    }
    setKurse(k)
  }

  const changeValue = (
    e,
    type,
    id,
    sub,
    date = false,
    checkbox = false,
    changeRequested = false
  ) => {
    let k = Object.assign({}, kurse)
    let termin = {}
    let name = e.target.name.split('-').reverse()[1]
    let value = checkbox ? e.target.checked : e.target.value
    if (date) value = formatDateBack(value)
    if (changeRequested) {
      k[type].requestedOnly = value
    } else if (sub) {
      k[type][sub].termine.find((t) => t.id === id)[name] = value
    } else {
      k[type].termine.find((t) => t.id === id)[name] = value
    }
    setKurse(k)
  }

  const changeTerminPreis = (e, type, subtype = null, termin = false) => {
    let k = Object.assign({}, kurse)
    const value = parseInt(e.target.value)
    const target = termin ? 'terminAnzahl' : 'preis'
    if (subtype) {
      k[type][subtype][target] = value
    } else {
      k[type][target] = value
    }
    setKurse(k)
  }

  const getKurse = () => {
    api.get('/kurse.json').then((res) => {
      setKurse({})
      setKurse(res.data)
    })
  }

  const createToast = (text) => {
    setToastTitle(text)
    setShowToast(true)
    setTimeout(() => {
      setFadeIn(true)
      setTimeout(() => {
        setFadeIn(false)
        setTimeout(() => {
          setShowToast(false)
        }, 1000)
      }, 7500)
    }, 250)
  }

  const deleteTermin = (type, id, sub) => {
    api({
      method: 'post',
      url: '/scripts/deleteTermin.php',
      data: {
        id,
        type,
        subtype: sub,
      },
    }).then((res) => {
      getKurse()
      createToast('Termin erfolgreich gelöscht')
    })
  }

  const changeFile = (e, setFile) => {
    if (
      e.target.files.length > 0 &&
      e.target.files[0].type === 'application/pdf'
    ) {
      setFileError(null)
      const file = e.target.files[0]
      setFile(file)
    } else {
      setFileError('Falsches Dateiformat!')
    }
  }

  const headingsRow = (type, termine, idx) => {
    const termin = termine[0]
    let colsNum = Object.keys(termin).length - 1
    if (termin.description) colsNum--
    return (
      <div
        className={`grid grid-flow-col mt-4 gap-8 text-center font-semibold`}
        style={{ gridTemplateColumns: `repeat(${colsNum}, minmax(0, 1fr))` }}
      >
        {termin.from && <span>{headings.from}</span>}
        {termin.to && <span>{headings.to}</span>}
        {termin.time && <span>{headings.time}</span>}
        {termin.day && <span>{headings.day}</span>}
        {termin.online !== undefined && <span>{headings.online}</span>}
        {termin.full !== undefined && <span>{headings.full}</span>}
        <div className="invisible">
          <FontAwesomeIcon
            className="cursor-pointer"
            icon={faTrashAlt}
            size="1x"
          />
        </div>
      </div>
    )
  }

  const row = (type, termin, idx, subtype) => {
    let colsNum = Object.keys(termin).length - 1
    if (termin.description !== undefined) {
      colsNum = colsNum - 1
    }

    return (
      <div
        key={idx}
        className={`w-full grid grid-flow-col mt-4 gap-8`}
        style={{ gridTemplateColumns: `repeat(${colsNum}, minmax(0, 1fr))` }}
      >
        {termin.from && (
          <input
            type="date"
            name={`${type}-from-${termin.id}`}
            id={`${type}-from-${termin.id}`}
            defaultValue={formatDate(termin.from)}
            onChange={(e) =>
              changeValue(
                e,
                subtype || type,
                termin.id,
                subtype ? type : null,
                true
              )
            }
            className="block focus:outline-none bg-transparent pb-1.5 leading-tight border-gray-300 border-b cursor-pointer min-w-min"
          />
        )}
        {termin.to && (
          <input
            type="date"
            name={`${type}-to-${termin.id}`}
            id={`${type}-to-${termin.id}`}
            defaultValue={formatDate(termin.to)}
            onChange={(e) =>
              changeValue(
                e,
                subtype || type,
                termin.id,
                subtype ? type : null,
                true
              )
            }
            className="block focus:outline-none bg-transparent pb-1.5 leading-tight border-gray-300 border-b cursor-pointer min-w-min"
          />
        )}
        {termin.time && (
          <input
            type="time"
            name={`${type}-time-${termin.id}`}
            id={`${type}-time-${termin.id}`}
            defaultValue={termin.time}
            onChange={(e) =>
              changeValue(e, subtype || type, termin.id, subtype ? type : null)
            }
            className="block focus:outline-none bg-transparent pb-1.5 leading-tight border-gray-300 border-b cursor-pointer min-w-min"
          />
        )}
        {termin.day && (
          <select
            className="block focus:outline-none bg-transparent pb-1.5 leading-tight border-b cursor-pointer"
            name={`${type}-day-${termin.id}`}
            id={`${type}-day-${termin.id}`}
            defaultValue={termin.day}
            onChange={(e) =>
              changeValue(e, subtype || type, termin.id, subtype ? type : null)
            }
          >
            {[
              'Montag',
              'Dienstag',
              'Mittwoch',
              'Donnerstag',
              'Freitag',
              'Samstag',
              'Sonntag',
            ].map((option, index) => {
              return (
                <option key={index} value={`${option}`}>
                  {option}
                </option>
              )
            })}
          </select>
        )}
        {termin.online !== undefined && (
          <div className="w-full h-full flex justify-center items-center">
            <input
              type="checkbox"
              name={`${type}-online-${termin.id}`}
              id={`${type}-online-${termin.id}`}
              defaultChecked={termin.online}
              value="on"
              onChange={(e) =>
                changeValue(
                  e,
                  subtype || type,
                  termin.id,
                  subtype ? type : null,
                  false,
                  true
                )
              }
              className="block focus:outline-none bg-transparent pb-1.5 leading-tight border-gray-300 border-b cursor-pointer mx-auto"
            />
            {!termin.online && (
              <input
                type="hidden"
                name={`${type}-online-${termin.id}`}
                className="hidden"
                value="off"
              />
            )}
          </div>
        )}
        {termin.full !== undefined && (
          <div className="w-full h-full flex justify-center items-center">
            <input
              type="checkbox"
              name={`${type}-full-${termin.id}`}
              id={`${type}-full-${termin.id}`}
              defaultChecked={termin.full}
              value="on"
              onChange={(e) =>
                changeValue(
                  e,
                  subtype || type,
                  termin.id,
                  subtype ? type : null,
                  false,
                  true
                )
              }
              className="block focus:outline-none bg-transparent pb-1.5 leading-tight border-gray-300 border-b cursor-pointer mx-auto"
            />
            {!termin.full && (
              <input
                type="hidden"
                name={`${type}-full-${termin.id}`}
                value="off"
              />
            )}
          </div>
        )}
        <div>
          <FontAwesomeIcon
            className="cursor-pointer hover:text-red-500 transition-colors duration-300"
            icon={faTrashAlt}
            size="1x"
            onClick={() =>
              deleteTermin(subtype || type, termin.id, subtype ? type : null)
            }
          />
        </div>
      </div>
    )
  }

  const createTerminAndPrice = (type, subtype) => {
    return (
      <div className="flex items-center mt-5">
        {subtype !== 'Offene Gruppe' && (
          <div className="mr-5">
            <span>Termine: </span>
            <input
              type="number"
              name={`${subtype ?? type}-termine`}
              id={`${subtype ?? type}-termine`}
              min={0}
              max={30}
              className="ml-2 text-center border-b border-primary outline-none"
              defaultValue={parseInt(
                subtype
                  ? kurse[type][subtype].terminAnzahl
                  : kurse[type].terminAnzahl
              )}
              onChange={(e) => changeTerminPreis(e, type, subtype, true)}
            />
          </div>
        )}
        <div>
          <span>
            Preis {subtype === 'Offene Gruppe' ? ' pro Stunde' : ''}:{' '}
          </span>
          <input
            type="number"
            name={`${subtype ?? type}-preis`}
            id={`${subtype ?? type}-preis`}
            min={0}
            className="ml-2 text-center border-b border-primary outline-none w-24"
            defaultValue={parseInt(
              subtype ? kurse[type][subtype].preis : kurse[type].preis
            )}
            onChange={(e) => changeTerminPreis(e, type, subtype)}
          />
          <span>,- €</span>
        </div>
      </div>
    )
  }

  const requestedButton = (type, current) => {
    return (
      <div>
        <input
          type="checkbox"
          name={`${type}-requestedOnly`}
          id={`${type}-requestedOnly`}
          defaultChecked={current}
          value="on"
          onChange={(e) => changeValue(e, type, null, null, false, true, true)}
          className="block focus:outline-none bg-transparent pb-1.5 leading-tight border-gray-300 border-b cursor-pointer min-w-min"
        />
        {!current && (
          <input
            type="hidden"
            name={`${type}-requestedOnly`}
            className="hidden"
            value="off"
          />
        )}
      </div>
    )
  }

  return (
    showData && (
      <div className="pt-20 pb-10 sm:pb-16 sm:px-0 max-w-6xl mx-auto relative">
        <h1 className="font-bold text-3xl sm:text-4xl mt-10 text-center ">
          Dashboard
        </h1>
        <div className="mt-10 px-10">
          <h2 className="font-semibold text-2xl text-center sm:text-left">
            Kurse
          </h2>
          <form className="mt-6" action="scripts/saveTermine.php" method="post">
            {Object.entries(kurse).map(([type, data], i) => {
              console.log(data)
              const termine = data.termine
              return (
                <div key={i} className="mb-5">
                  <h3 className="font-semibold  text-xl">{type}</h3>
                  <div className="flex itemsc-center gap-4 my-4">
                    <span>Kurs nur auf Anfrage:</span>
                    {requestedButton(type, data.requestedOnly)}
                  </div>
                  {termine && termine.length > 0 && (
                    <div>
                      {createTerminAndPrice(type)}
                      <div className="overflow-x-auto">
                        <div style={{ minWidth: '950px' }} className="mb-1">
                          {headingsRow(type, termine, i)}
                          {termine.map((termin, idx) => {
                            return row(type, termin, idx)
                          })}
                        </div>
                      </div>
                      <div className="mt-3">
                        <FontAwesomeIcon
                          className="ml-2 cursor-pointer text-primary-dark transition-colors duration-300"
                          icon={faPlus}
                          size="1x"
                          onClick={() => addTermin(type)}
                        />
                      </div>
                    </div>
                  )}
                  {termine && termine.length == 0 && (
                    <div>
                      <p className="mt-2">Kein Termine!</p>
                      <button
                        onClick={() => addTermin(type)}
                        type="button"
                        className="mt-4 border py-0.5 px-4 rounded-lg border-primary-dark focus:outline-none hover:bg-primary-dark hover:text-white transition-colors duration-300"
                      >
                        Termin hinzufügen
                      </button>
                    </div>
                  )}
                  {!termine &&
                    Object.entries(data).map(([feldType, feldData], i) => {
                      const feldTermine = feldData.termine
                      if (!feldTermine) return
                      if (feldTermine.length == 0) {
                        return (
                          <div key={i}>
                            <h3 className="mt-5 font-semibold">{feldType}</h3>
                            <p className="mt-2">Kein Termine!</p>
                            <button
                              onClick={() => addTermin(type, feldType)}
                              type="button"
                              className="mt-4 border py-0.5 px-4 rounded-lg border-primary-dark focus:outline-none hover:bg-primary-dark hover:text-white transition-colors duration-300"
                            >
                              Termin hinzufügen
                            </button>
                          </div>
                        )
                      }
                      return (
                        <div key={i} className="mt-5">
                          <h3 className="font-semibold">{feldType}</h3>
                          {createTerminAndPrice(type, feldType)}
                          <div className="overflow-x-auto">
                            <div style={{ minWidth: '950px' }} className="mb-1">
                              {headingsRow(feldType, feldTermine, i)}
                              {feldTermine.map((termin, idx) => {
                                return row(feldType, termin, idx, type)
                              })}
                            </div>
                          </div>
                          <div className="mt-3">
                            <FontAwesomeIcon
                              className="ml-2 cursor-pointer text-primary-dark transition-colors duration-300"
                              icon={faPlus}
                              size="1x"
                              onClick={() => addTermin(type, feldType)}
                            />
                          </div>
                        </div>
                      )
                    })}
                </div>
              )
            })}
            <button
              type="submit"
              className="mt-4 border py-0.5 px-4 rounded-lg border-primary-dark focus:outline-none hover:bg-primary-dark hover:text-white transition-colors duration-300"
            >
              Änderungen speichern
            </button>
          </form>
        </div>
        <div className="mt-16 px-10">
          <h2 className="font-semibold text-2xl text-center sm:text-left">
            Passwort ändern
          </h2>
          <div className="mt-2">
            <span className="text-gray-500 text-base">
              Länge zwischen 8 und 24 Zeichen. Mindestens 1 Großbuchstabe und 1
              Ziffer.
            </span>
          </div>
          <form
            className="flex flex-col md:flex-row items-center sm:gap-x-5 lg:gap-x-10 mt-8 sm:mt-3 form flex-wrap"
            action="scripts/changePW.php"
            method="post"
          >
            <div className="flex-1 mb-7 md:mb-0 relative">
              <input
                required
                type="password"
                name="old_password"
                placeholder=" "
                className="block w-full appearance-none focus:outline-none bg-transparent pt-3 pb-1.5 px-3 leading-tight border-b transition-colors duration-300"
              />
              <label
                htmlFor="firstname"
                className="absolute top-1 uppercase tracking-wide text-gray-500 text-sm font-semibold transition-all duration-300 left-2 transform translate-y-1/2 select-none pointer-events-none"
              >
                Altes Passwort
              </label>
            </div>
            <div className="flex-1 mb-7 md:mb-0 relative">
              <input
                required
                type="password"
                name="new_password"
                placeholder=" "
                className="block w-full appearance-none focus:outline-none bg-transparent pt-3 pb-1.5 px-3 leading-tight border-b transition-colors duration-300"
                pattern="[a-zA-Z0-9!§$%&/()=?+~*#-_@><]{8,}"
              />
              <label
                htmlFor="firstname"
                className="absolute top-1 uppercase tracking-wide text-gray-500 text-sm font-semibold transition-all duration-300 left-2 transform translate-y-1/2 select-none pointer-events-none"
              >
                Neues Passwort
              </label>
            </div>
            <div className="flex-1 mb-4 md:mb-0 relative">
              <input
                required
                type="password"
                name="new_password_confirm"
                placeholder=" "
                className="block w-full appearance-none focus:outline-none bg-transparent pt-3 pb-1.5 px-3 leading-tight border-b transition-colors duration-300"
                pattern="[a-zA-Z0-9!§$%&/()=?+~*#-_@><]{8,}"
              />
              <label
                htmlFor="firstname"
                className="absolute top-1 uppercase tracking-wide text-gray-500 text-sm font-semibold transition-all duration-300 left-2 transform translate-y-1/2 select-none pointer-events-none"
              >
                Passwort Wiederholen
              </label>
            </div>
            <div
              style={{ flexBasis: '100%' }}
              className="h-0 hidden md:block lg:hidden"
            ></div>
            <button
              type="submit"
              className="mt-4 lg:mt-0 border py-0.5 px-4 rounded-lg border-primary-dark focus:outline-none hover:bg-primary-dark hover:text-white transition-colors duration-300"
            >
              Passwort ändern
            </button>
          </form>
          <div>
            <span></span>
          </div>
        </div>
        <div className="mt-16 px-10">
          <h2 className="font-semibold text-2xl text-center sm:text-left">
            PDFs ändern
          </h2>
          {fileError && (
            <div className="mt-2">
              <span className="text-red-500">{fileError}</span>
            </div>
          )}
          <div className="mt-4 sm:mt-2">
            <h3 className="text-lg text-center sm:text-left">Preisliste</h3>
            <form
              className="flex flex-col sm:flex-row items-center sm:gap-x-5 lg:gap-x-10 mt-0 sm:mt-3 form flex-wrap"
              action="scripts/uploadFile.php"
              encType="multipart/form-data"
              method="post"
            >
              <div className="mb-2 md:mb-0 relative">
                <label
                  className="w-52 flex items-center justify-center mt-4 lg:mt-0 border py-1 px-6 rounded-lg text-gray-600 border-primary-dark cursor-pointer"
                  htmlFor="preisliste"
                >
                  <span className="text-base leading-normal w-full text-center">
                    {preisliste?.name ? preisliste.name : 'Auswählen...'}
                  </span>
                </label>
                <input
                  id="preisliste"
                  name="preisliste"
                  type="file"
                  className="hidden"
                  accept=".pdf"
                  onChange={(e) => changeFile(e, setPreisliste)}
                />
              </div>
              <button
                type="submit"
                className="text-gray-700 mt-4 lg:mt-0 border py-1 px-6 rounded-lg border-primary-dark focus:outline-none hover:bg-primary-dark hover:text-white transition-colors duration-300"
              >
                <span className="mr-2">Hochladen</span>
                <FontAwesomeIcon
                  className="cursor-pointer"
                  icon={faUpload}
                  size="sm"
                />
              </button>
            </form>
          </div>
          <div className="mt-8 sm:mt-2">
            <h3 className="text-lg text-center sm:text-left">AGB{"'"}s</h3>
            <form
              className="flex flex-col sm:flex-row items-center sm:gap-x-5 lg:gap-x-10 mt-0 sm:mt-3 form flex-wrap"
              action="scripts/uploadFile.php"
              encType="multipart/form-data"
              method="post"
            >
              <div className="mb-2 md:mb-0 relative">
                <label
                  className="w-52 flex items-center justify-center mt-4 lg:mt-0 border py-1 px-6 rounded-lg text-gray-600 border-primary-dark cursor-pointer"
                  htmlFor="agb"
                >
                  <span className="text-base leading-normal w-full text-center">
                    {agb?.name ? agb.name : 'Auswählen...'}
                  </span>
                </label>
                <input
                  id="agb"
                  name="agb"
                  type="file"
                  className="hidden"
                  accept=".pdf"
                  onChange={(e) => changeFile(e, setAgb)}
                />
              </div>
              <button
                type="submit"
                className="text-gray-700 mt-4 lg:mt-0 border py-1 px-6 rounded-lg border-primary-dark focus:outline-none hover:bg-primary-dark hover:text-white transition-colors duration-300"
              >
                <span className="mr-2">Hochladen</span>
                <FontAwesomeIcon
                  className="cursor-pointer"
                  icon={faUpload}
                  size="sm"
                />
              </button>
            </form>
          </div>
        </div>
        {showToast && (
          <div
            className={`fixed top-24 flex justify-between items-center w-60 h-10 px-2.5 sm:w-96 sm:h-12 md:px-5 border rounded-lg border-primary-dark bg-white z-50 ${
              fadeIn ? 'right-4' : ' -right-96 '
            } transition-all duration-1000 ease-in-out`}
          >
            <h1 className="text-semibold text-sm text-gray-600">
              {/* Termin erfolgreich gelöscht */}
              {toastTitle}
            </h1>
            <FontAwesomeIcon
              className="cursor-pointer text-primary-dark opacity-75"
              icon={faCheck}
              size="lg"
            />
          </div>
        )}
      </div>
    )
  )
}

export default Dashboard
