import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

function OutlineButton({ text, style, onClick, color }) {
  return (
    <div
      onClick={onClick}
      className={`border border-primary rounded-md grid place-items-center hover:bg-primary hover:text-white transition-colors duration-300 ${
        style ? style : ''
      }`}
    >
      {text}
    </div>
  )
}

OutlineButton.propTypes = {
  text: PropTypes.string,
  style: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
}

export default OutlineButton
