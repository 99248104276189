import api from 'config/api'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

function Kurse() {
  const [termine, setTermine] = useState({})
  useEffect(() => {
    api.get('/kurse.json').then((res) => setTermine(res.data))
  }, [])
  return (
    <div className="pt-20 pb-10 sm:pb-16 px-5 w-full sm:w-2/3 ipad:w-1/2 sm:px-0 max-w-3xl mx-auto">
      <h1 className="font-bold text-3xl sm:text-4xl mt-10 text-center sm:text-left ">
        Kurse & Termine
      </h1>
      <div className="px-6 sm:px-0">
        {Object.entries(termine).map(([key, value], i) => {
          console.log(value)
          const isFeld =
            value.description == undefined && value.kosten == undefined
          return (
            <div key={i} className="my-10">
              <h2
                className={`text-xl font-semibold ${
                  isFeld ? 'text-feldenkrais' : 'text-bewegung'
                }`}
              >
                {key}-Termine
              </h2>
              {value.requestedOnly && (
                <div className="font-semibold my-2">
                  <h4>Kurse auf Anfrage.</h4>
                </div>
              )}
              {!value.requestedOnly && (
                <>
                  {isFeld ? (
                    <div className="mb-4">
                      {Object.entries(value).map(
                        ([feldKey, feldVal], feldI) => {
                          if (!feldVal.termine) return
                          return (
                            <div key={feldI} className="mt-4">
                              <h3 className="text-lg">{feldKey}</h3>
                              <div>
                                <div className="mt-2">
                                  <p className="mb-2 font-semibold">
                                    {feldVal.description}
                                  </p>
                                  <p className="font-semibold mb-2">
                                    Preis: {feldVal.kosten}
                                  </p>
                                  <ul>
                                    {feldVal.termine.map((term, termI) => {
                                      return (
                                        <li
                                          key={termI}
                                          className={`mb-1.5 ${
                                            term.full
                                              ? 'text-gray-500'
                                              : 'text-black'
                                          }`}
                                        >
                                          <span className="mr-1">
                                            {!term.to
                                              ? 'jeweils ' + term.day + 's'
                                              : term.day}
                                            ,{' '}
                                          </span>
                                          {term.to && (
                                            <span className="mr-1">
                                              {term.from} - {term.to}
                                            </span>
                                          )}
                                          {!term.to && (
                                            <span className="mr-1">
                                              ab {term.from}
                                            </span>
                                          )}
                                          <span className="mr-1 hidden sm:inline">
                                            -
                                          </span>
                                          <br className="sm:hidden"></br>
                                          <span>Beginn: {term.time} Uhr</span>
                                          {term.online && (
                                            <p
                                              className={`${
                                                !term.full ? 'mb-2' : ''
                                              } italic text-gray-500`}
                                            >
                                              {term.description}
                                            </p>
                                          )}
                                          {term.full && (
                                            <span className="italic font-semibold">
                                              {!term.online && <br></br>}Dieser
                                              Kurs ist leider ausgebucht!
                                            </span>
                                          )}
                                        </li>
                                      )
                                    })}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      )}
                    </div>
                  ) : (
                    <div className="my-4">
                      <p className="mb-2">{value.description}</p>
                      <p className="font-semibold mb-2">
                        Preis: {value.kosten}
                      </p>
                      <ul>
                        {value.termine.map((term, termI) => {
                          return (
                            <li
                              key={termI}
                              className={`mb-1.5 ${
                                term.full ? 'text-gray-500' : 'text-black'
                              }`}
                            >
                              <span className="mr-1">{term.day}, </span>
                              {!term.date && (
                                <span className="mr-1">
                                  {term.from} - {term.to}
                                </span>
                              )}
                              {term.date && (
                                <span className="mr-1">{term.date}</span>
                              )}
                              <span className="mr-1 hidden sm:inline"> - </span>
                              <br className="sm:hidden"></br>
                              <span>Beginn: {term.time} Uhr</span>
                              {term.online && (
                                <p
                                  className={`${
                                    !term.full ? 'mb-2' : ''
                                  } italic text-gray-500`}
                                >
                                  {term.description}
                                </p>
                              )}
                              {term.full && (
                                <span className="italic font-semibold">
                                  {!term.online && <br></br>}Dieser Kurs ist
                                  leider ausgebucht!
                                </span>
                              )}
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  )}
                </>
              )}
              <Link
                to={`/kontakt?betreff=Kurse&kurs=${key}`}
                className={`${isFeld ? 'text-feldenkrais' : 'text-bewegung'}`}
              >
                <span>Anmeldung anfragen</span>
                <FontAwesomeIcon
                  className="ml-2 cursor-pointer"
                  icon={faArrowRight}
                  size="xs"
                />
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Kurse
