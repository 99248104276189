import About from 'components/Home/About'
import Angebot from 'components/Home/Angebot'
import Kontakt from 'components/Home/Kontakt'
import Landing from 'components/Home/Landing'
import Team from 'components/Home/Team'

function Home() {
  return (
    <div className="w-full">
      <Landing></Landing>
      <About></About>
      <Team></Team>
      <Angebot></Angebot>
      <Kontakt></Kontakt>
    </div>
  )
}

export default Home
