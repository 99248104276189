import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

function AngebotCard({ to, img, title, info, style }) {
  return (
    <div className="w-10/12 md:w-5/12 xl:w-full shadow-md rounded-t-xl overflow-hidden transform transition-transform duration-300 hover:scale-105">
      <div className="w-full overflow-hidden relative">
        <Link to={to}>
          <img
            src={img}
            alt="Massage"
            className="object-cover h-60 2xl:h-72 w-full transform transition-transform duration-300 hover:scale-110"
          />
        </Link>
      </div>

      <div className="w-full px-6 py-6">
        <Link to={to}>
          <h1 className="text-xl font-semibold text-gray-700 hover:underline">
            {title}
          </h1>
        </Link>
        <p className="text-lg mt-2 text-gray-600">{info}</p>
        <Link to={to}>
          <div
            className={`w-full mt-6 flex justify-end items-center cursor-pointer transition-colors duration-300 ${style}`}
          >
            <span className="text-lg mr-2">{title}</span>
            <FontAwesomeIcon className="mt-1" icon={faArrowRight} size="1x" />
          </div>
        </Link>
      </div>
    </div>
  )
}

AngebotCard.propTypes = {
  to: PropTypes.string,
  img: PropTypes.string,
  title: PropTypes.string,
  info: PropTypes.string,
  style: PropTypes.string,
}

export default AngebotCard
