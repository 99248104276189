import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

function ProduktCard({ produkt, color }) {
  const image = require('../assets/images/' + produkt.imgSrc)
  return (
    <Link to={produkt.href}>
      <div className="h-full w-full shadow-md rounded-xl overflow-hidden transform transition-transform duration-300 relative">
        <div className="w-full overflow-hidden relative">
          <img
            src={image.default}
            alt={produkt.type}
            className="object-cover h-60 2xl:h-72 w-full transform transition-transform duration-300 hover:scale-110"
          />
        </div>

        <div className="w-full px-6 py-6">
          <h1 className={`text-xl font-semibold ${color} hover:underline`}>
            {produkt.type}
          </h1>
          <p className="text-lg mt-2 text-gray-600 cursor-auto select-text pointer-events-auto">
            {produkt.description}
          </p>
          <div
            className={`w-full mt-10 flex justify-end items-center cursor-pointer transition-colors duration-300 ${color}`}
          >
            <FontAwesomeIcon
              className="absolute bottom-5 transition-transform duration-300 transform hover:scale-125"
              icon={faArrowRight}
              size="1x"
            />
          </div>
        </div>
      </div>
    </Link>
  )
}

ProduktCard.propTypes = {
  produkt: PropTypes.object,
  color: PropTypes.string,
}

export default ProduktCard
