import PropTypes from 'prop-types'
import Accordion from 'components/Accordion'

import team from 'data/team.json'

import 'styles/Team.scss'

function Team() {
  return (
    <div className="w-full pt-16">
      <div className="px-6 py-8 max-w-6xl mx-auto">
        <h1 className="font-semibold text-4xl sm:text-5xl text-gray-600 mb-14 text-center">
          Das Team
        </h1>
        <div className="w-full sm:w-4/5 flex flex-col items-center gap-16 mx-auto">
          {team.map((member, i) => {
            const image = require('../../assets/images/team/' + member.imgSrc)
            let linkSplit
            if (member.link) {
              linkSplit = member.linkText.split('%link%')
            }
            return (
              <div
                key={i}
                className={`w-full flex flex-col items-center sm:items-start gap-8 sm:gap-16 ${
                  i % 2 == 0 ? 'sm:flex-row' : 'sm:flex-row-reverse'
                } `}
              >
                <div className="w-40 overflow-hidden rounded-md h-full">
                  <img
                    src={image.default}
                    alt={member.name}
                    className="object-contain w-full"
                  />
                </div>
                <div className={`flex-1 pt-1 text-center sm:text-left`}>
                  <h2 className="font-semibold text-lg mb-4">{member.name}</h2>
                  <p className="whitespace-pre-line text-base line">
                    {member.text}
                  </p>
                  {member.link && (
                    <>
                      <br />
                      <p className="whitespace-pre-line text-base line">
                        {linkSplit[0] + ' '}
                        <a
                          href={'https://' + member.link}
                          target="_blank"
                          rel="noreferrer"
                          className="underline hover cursor-pointer hover:text-primary transition-colors duration-300"
                        >
                          {member.link}
                        </a>
                        {linkSplit[1]}
                      </p>
                    </>
                  )}
                  {member.more && (
                    <Accordion
                      title="Mehr über mich"
                      titleStyle="text-primary hover:text-primary-dark"
                      dropwdownIcon={<span>...</span>}
                    >
                      {member.more.map((moreEntry, idx) => {
                        return (
                          <p
                            key={idx}
                            className={`accordion-child ${
                              moreEntry.type === 'header'
                                ? 'accordion-header'
                                : ''
                            }`}
                          >
                            {moreEntry.text}
                          </p>
                        )
                      })}
                    </Accordion>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Team
