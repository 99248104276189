import massagen from 'data/massagen.json'
import ProduktCard from 'components/ProduktCard'

function MassagenView() {
  return (
    <div className="pt-20 pb-10 w-full min-h-screen px-10">
      <div className="w-full max-w-6xl mx-auto md:w-11/12 lg:w-full mt-10 text-center">
        <h1 className="text-4xl text-primary font-semibold">Massagen</h1>
        <p className="mx-auto mt-5 md:w-3/4 lg:w-1/2 text-lg">
          Massage nach Erkrankung oder Verletzung ist schon lange ein wichtiger
          Bestandteil von Therapiekonzepten. Instinktiv reiben oder kneten wir
          an schmerzhaften Stellen.
        </p>
      </div>
      <div className="w-full max-w-6xl mx-auto md:w-11/12 lg:w-full mt-10 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-12 justify-center">
        {massagen.map((mass, i) => {
          return (
            <div key={i} className="h-full">
              <ProduktCard produkt={mass} color="text-primary" />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MassagenView
