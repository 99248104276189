import React from 'react'
import ReactDOM from 'react-dom'
import 'index.css'
import App from './App'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import ScrollToTop from 'components/ScrollToTop'

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop />
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)
