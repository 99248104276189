import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'

function PageUp() {
  const up = () =>
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  return (
    <div
      className="sm:absolute text-white sm:right-2 sm:bottom-2 sm:border border-white rounded-full md:rounded-lg px-3 py-1.5 sm:hover:bg-white sm:hover:text-primary cursor-pointer transition-colors duration-200"
      onClick={up}
    >
      <span className="sm:hidden md:inline uppercase text-sm">Nach Oben</span>
      <FontAwesomeIcon
        icon={faChevronUp}
        className="ml-2 sm:ml-0 md:ml-2"
        size="sm"
      ></FontAwesomeIcon>
    </div>
  )
}

export default PageUp
