import { Link } from 'react-scroll'

function ScrollIndicator() {
  return (
    <Link to="about" smooth={true} offset={-80} duration={1000}>
      <div className="absolute left-1/2 transform -translate-x-1/2 bottom-2 sm:bottom-12 h-14 w-7 rounded-full border-2 border-primary cursor-pointer">
        <div className="indicator"></div>
      </div>
    </Link>
  )
}

export default ScrollIndicator
