import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import api from 'config/api'
import { useSearchParams } from 'react-router-dom'

function LoginModal({ setModal, modalState, setModalState }) {
  const [fadeIn, setFadeIn] = useState(false)

  let [urlparams, setUrlparams] = useSearchParams()
  let error = urlparams.get('error')

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const closeModal = (e) => {
    setModalState('fadeOut')
    // setFadeIn(false)
  }

  // const login = (e) => {
  //   api.post('/scripts/login.php', {})
  // }

  useEffect(() => {
    if (modalState == 'fadeIn') setFadeIn(true)
  }, [])

  return (
    <div
      className={`fixed w-full opacity-0 h-full z-40 flex items-center justify-center transition-all duration-850 ${
        fadeIn ? 'fadeIn' : ''
      } ${modalState == 'fadeOut' ? 'fadeOut' : ''}`}
    >
      <div
        className="fixed top-0 bottom-0 left-0 right-0 bg-black opacity-50 lg:opacity-75 z-40 cursor-pointer"
        onClick={closeModal}
      ></div>
      <div className="relative mx-6 md:mx-auto w-full md:w-1/2 lg:w-1/3 z-50 m-8">
        <div className="shadow-modal bg-white rounded-lg p-8">
          <div className="flex justify-end mb-6">
            <div className="absolute top-7 right-9 hover:text-red-500 transition-colors duration-300">
              <FontAwesomeIcon
                className="cursor-pointer"
                icon={faTimes}
                size="lg"
                onClick={closeModal}
              />
            </div>
          </div>

          <h1 className="text-center text-2xl text-green-dark">Login</h1>

          <form
            className="pt-6 pb-2 my-2"
            method="post"
            action="/scripts/login.php"
          >
            <div className="mb-4">
              <label
                className="block text-sm text-primary-dark font-semibold mb-2"
                htmlFor="username"
              >
                Username
              </label>
              <input
                required
                className="appearance-none outline-none border-b-2 border-gray-200 focus:border-primary-dark transition-all duration-300 w-full py-2 px-3 text-grey-darker"
                id="username"
                name="username"
                type="text"
                placeholder="Username"
                maxLength="20"
              />
            </div>
            <div className="mb-6">
              <label
                className="block text-sm text-primary-dark font-semibold mb-2"
                htmlFor="password"
              >
                Passwort
              </label>
              <input
                required
                className="appearance-none outline-none border-b-2 border-gray-200 focus:border-primary-dark transition-all duration-300 w-full py-2 px-3 text-grey-darker"
                id="password"
                name="password"
                type="password"
                placeholder="Passwort"
                maxLength="24"
              />
            </div>
            <div className="block md:flex items-center justify-between">
              <div>
                <button
                  className="bg-primary hover:bg-primary-dark text-white font-semibold py-2 px-8 rounded border-0"
                  type="submit"
                >
                  Log In
                </button>
              </div>
            </div>
            {error && (
              <div className="block md:flex items-center justify-between mt-3">
                <div>
                  <span className="text-red-500">
                    Username oder Passwort nicht korrekt!
                  </span>
                  <br />
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

LoginModal.propTypes = {
  setModal: PropTypes.func,
  modalState: PropTypes.string,
  setModalState: PropTypes.func,
}

export default LoginModal
