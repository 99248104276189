import PropTypes from 'prop-types'

function Button({ text, style, onClick, disabled, children }) {
  return (
    <div
      onClick={onClick}
      disabled={disabled}
      className={`bg-primary rounded-md flex items-center justify-center text-white hover:bg-primary-dark transition-colors duration-300 cursor-pointer ${
        style ? style : ''
      }`}
    >
      {text}
      {children}
    </div>
  )
}

Button.propTypes = {
  text: PropTypes.string,
  style: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

export default Button
