import { Link } from 'react-router-dom'

function Kontakt() {
  return (
    <div className="w-full bg-gray-50 py-16 sm:py-24">
      <div className="flex flex-col xl:flex-row w-4/5 max:w-3/5 m-auto max-w-6xl items-center justify-center xl:justify-start">
        <div className="flex-1 text-center xl:text-left">
          <h1 className="font-semibold text-4xl sm:text-5xl text-gray-600">
            <Link to="/kontakt">
              <span className="relative underlined hover cursor-pointer hover:text-primary transition-colors duration-300">
                Kontakt
              </span>
            </Link>
          </h1>
          <div className="mt-12 xl:mt-16">
            <div>
              <p className="font-semibold text-xl">Öffnungszeiten</p>
              <p className="text-xl">Montag - Freitag: 07:00 - 20:00 Uhr</p>
            </div>
            <div className="mt-4">
              <span className="font-semibold text-xl">Tel.: </span>
              <br className="xl:hidden" />
              <span className="text-xl">+43 1 812 44 71</span>
            </div>
            <div className="mt-4">
              <span className="font-semibold text-xl">E-Mail: </span>
              <br className="xl:hidden" />
              <a
                href="mailto:praxis@mabefe.at"
                target="_blank"
                rel="noreferrer"
              >
                <span className="text-xl transition-colros duration-200 hover:text-primary">
                  praxis@mabefe.at
                </span>
              </a>
            </div>
            <div className="mt-4">
              <p className="font-semibold text-xl">Adresse</p>
              <a
                href="https://www.google.com/maps/place/Arndtstra%C3%9Fe+87,+1120+Wien"
                target="_blank"
                rel="noreferrer"
              >
                <p className="text-xl transition-colros duration-200 hover:text-primary">
                  Arndtstraße 87/5, 1120 Wien
                </p>
              </a>
            </div>
          </div>
        </div>
        <div className="flex-1 mt-16 xl:mt-0 w-full">
          <iframe
            className="w-full"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d940.558420600603!2d16.330781560624455!3d48.181552740461406!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476da81661153985%3A0x1a9a103d7f3d2fdd!2sArndtstra%C3%9Fe%2087%2F5%2C%201120%20Wien!5e0!3m2!1sde!2sat!4v1635249651266!5m2!1sde!2sat"
            // width="650"
            height="450"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  )
}

export default Kontakt
